@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background: rgb(255,132,245);
  background: -moz-linear-gradient(245deg, rgba(255,132,245,1) 0%, rgba(247,83,83,1) 44%, rgba(255,197,29,1) 100%);
  background: -webkit-linear-gradient(245deg, rgba(255,132,245,1) 0%, rgba(247,83,83,1) 44%, rgba(255,197,29,1) 100%);
  background: linear-gradient(245deg, rgba(255,132,245,1) 0%, rgba(247,83,83,1) 44%, rgba(255,197,29,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff84f5",endColorstr="#ffc51d",GradientType=1);
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  text-decoration: none;
}

.linkText {
  text-decoration: none;
  color:rgb(117, 248, 248);
  transition: 500ms;
  text-shadow: 2px 2px #ff0000;
}

.linkText:hover {
  text-decoration: none;
  color:rgb(192, 12, 123)
}

.title {
  color: white;
  text-shadow: 2px 2px #ff0000;
  font-family: 'Bungee', cursive;
  font-size: 30px;
  cursor: pointer;
  transition: 400ms;
}

.title:hover {
  color: pink;
  text-shadow: 2px 2px #ff0000;
  font-family: 'Bungee', cursive;
  font-size: 30px;
}

.headings {
  color: white;
  text-shadow: 2px 2px #ff0000;
  font-family: 'Bungee', cursive;
  font-size: 30px;
  transition: 400ms;
}

.output {
  font-family:"Abril Fatface", sans-serif;
  background: linear-gradient(to right,rgb(250, 157, 242),rgb(255, 255, 255),rgb(255, 197, 29));
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: white;
  font-size:25px;
  width:100%;
  display:block;
  text-align:center;
  transition: color .2s ease-in-out;
  font-weight:900;
}

.output:hover{
  color:rgba(0, 0, 0, 0);
  cursor: pointer;
}

@keyframes rainbow { 
  0%{background-position:left}
  50%{background-position:right}
  100%{background-position:left}
}

.rowStyle {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 100px;
}

.bodyText {
  color: white;
  text-shadow: 2px 2px #ff0000;
  font-family: 'Bungee', cursive;
}

.smallText {
  font-family: 'Bungee', cursive;
  color: white;
}


.userJokeText {
  font-family:"Abril Fatface", sans-serif;
  color: white;
}

.bi {
  margin-left: 10px;
}

.bi-emoji-expressionless {
  margin-right: 25px;
  transition: 400ms;
  cursor: pointer;
}

.bi-emoji-expressionless:hover {
  color:red;
}

.bi-emoji-laughing {
  transition: 400ms;
  cursor: pointer;
}

.bi-emoji-laughing:hover {
  color:green;
}

.tally {
  font-family:"Abril Fatface", sans-serif;
  color: white;
}

.btn {
  border: 2px solid #3c73ff;
  color: #fff;
  transition: .3s;
}
.btn:hover {
  box-shadow: 8px 8px #99bdff;
  transition: .3s;
}

.btn:active {
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(4px);
}

